<template>
  <div class="launcher">
    <div class="launcher__content">
      <div class="launcher__header">
        <div class="launcher__logo">
          <img :src="$store.getters.themeLogo" class="logo-secondary d--middle" />
          <span>{{ $store.state.config.name }}</span>
        </div>
        <el-input :placeholder="$tfo('search')" v-model="search" class="launcher__search">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div class="launcher__menu">
        <template v-for="item in filteredMenu">
          <a v-if="item.name !== 'launcher'" :href="$router.resolve(item.path).href" @click.stop.capture="itemMenuHandler($event, item)" :key="item.name">
            <div class="launcher__item" :class="{ launcher_hover: isEditMode, launcher_active: isActiveItem(item) }">
              <i v-if="item.classes" class="launcher__icon" :class="item.classes" />
              <span class="text-s">{{ getText(item) }}</span>
              <span v-if="isEditMode" class="launcher__checkbox">
                <el-checkbox v-model="state.user_menu" :label="item.name">&nbsp;</el-checkbox>
              </span>
            </div>
          </a>
        </template>
      </div>
      <div class="launcher__footer">
        <el-button type="default" v-if="!isEditMode" @click="toggleEditMode()">{{ $tfo('edit_in_menu') }}</el-button>
        <template v-else>
          <el-button type="primary" @click="toggleEditMode()">{{ $tfo('finish_editing_menu') }}</el-button>
          <el-button type="default" @click="setAll">{{ $tfo('set_all_menu_items') }}</el-button>
          <el-button type="default" @click="setToDefault()">{{ $tfo('reset_to_default_menu') }}</el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { canShowMenuItem } from '@/apps/common/menuItemChecker';

@Component({
  name: 'launcher'
})
export default class Launcher extends Vue {
  search = '';
  isEditMode = false;
  checked = true;

  get state() {
    return this.$store.state.launcher;
  }

  get items() {
    return this.$store.getters.menu.filter(canShowMenuItem);
  }

  get filteredMenu() {
    return this.items.filter((menuItem) => {
      const searchFilter = this.getText(menuItem).toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      return searchFilter;
    });
  }

  getText(item) {
    return item.i18n ? this.$tfo(item.i18n) : item.name;
  }

  itemMenuHandler(event, item) {
    if (event.shiftKey || event.ctrlKey || event.metaKey) {
      return;
    }
    if (this.isEditMode) {
      event.preventDefault();
      this.toggleItem(item);
    } else {
      this.$router.push(item.path);
    }
  }

  toggleItem({ name }) {
    this.$store.dispatch('toggleItemLauncher', name);
  }

  setAll() {
    const items = this.items.map((v) => v.name).filter((v) => !!v);
    this.$store.dispatch('setAllLauncher', items);
  }

  setToDefault() {
    this.$store.dispatch('setDefaultLauncher');
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;

    const event = new CustomEvent('menuEditMode', { detail: this.isEditMode });
    window.dispatchEvent(event);
  }

  isActiveItem(item) {
    return this.state.user_menu.indexOf(item.name) !== -1 && this.isEditMode;
  }

  destroyed() {
    this.isEditMode = false;
    const event = new CustomEvent('menuEditMode', { detail: this.isEditMode });
    window.dispatchEvent(event);
  }
}
</script>
